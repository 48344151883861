import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import React from "react"
import Layout from "../components/layout"
import img2 from "../images/Source_File_Mark 1 4.png"
import vector from "../images/Vector.png"
import Img from "gatsby-image"
import ContactSection from "../components/map"
import Footer from "../components/footer"
import vector2 from "../images/Source_File_Mark1 1.png"

const Franchise = probs => {
  const data = [
    {
      img: vector,
      content:
        "Expert advice on location and assistance in securing a suitable property.",
    },
    {
      img: vector,
      content: "Our template for store branding and catering equipment        ",
    },
    {
      img: vector,
      content: "Training for your team on the Spice Brew menu       ",
    },
    { img: vector, content: "Spice Brew POS system        " },
    {
      img: vector,
      content:
        "Assistance establishing relations with quality suppliers        ",
    },
    { img: vector, content: "Expert advice from your account manager        " },
    { img: vector, content: "Spice Brew branded consumables        " },
    { img: vector, content: "Support from our central kitchens" },
  ]

  const dataTwo = [
    { img: vector, content: "Evidence of financial stability        " },
    {
      img: vector,
      content:
        "Evidence that you have not been disqualified from being a Company Director",
    },
    { img: vector, content: "A one-off activation fee" },
    {
      img: vector,
      content:
        "Evidence of suitable Employer Liability, Public Liability and Buildings insurances",
    },
    { img: vector, content: "Monthly franchise fees" },
  ]

  return (
    <Layout>
      <Helmet>
        <title>Spice Brew | Welcome</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="description"
          content="Our business is expanding rapidly and we are looking for like minded people to share our vision of high quality modern Indian cuisine. Our mission is to create Spice Brew restaurants all over the UK and around the globe."
        />
        <meta name="keywords" content="" />
        <link rel="canonical" href="https://spice-brew.com/franchise" />
      </Helmet>

      <section
        style={{
          background:
            "linear-gradient(180deg, #074E81 0%, #074E81 38.23%, #074E81 100%)",
        }}
      >
        <div className="grid md:grid-cols-3 grid-cols-2 ">
          <div className="flex items-center justify-center">
            <div className="w-full">
              <Img
                fluid={probs.data.coverImgOne.childImageSharp.fluid}
                className=""
              />
            </div>
          </div>
          <div className="md:col-span-2 flex  items-center">
            <img
              src={img2}
              alt=""
              className="z-10  h-14 sm:h-20 md:h-28 lg:h-36 2xl:h-56"
            />

            <h1
              className="2xl:text-6xl lg:text-5xl md:text-3xl sm:text-2xl text-base text-white Moderat-Black sm:w-4/6 lg:w-3/6 md:w-3/6 w-auto"
              style={{
                textShadow: " 0px 8.42389px 16.8478px rgba(0, 0, 0, 0.5)",
              }}
            >
              Franchise Opportunity{" "}
            </h1>
          </div>
        </div>
      </section>

      <section className="bg-white flex justify-center">
        <div className="py-10 px-5 md:px-10 mb-10 xl-max-w-1200 lg-max-w-1000">
          <p className="text-navyBlue xl:text-xl lg:text-lg sm:text-base text-sm mb-3">
            Our business is expanding rapidly and we are looking for like minded
            people to share our vision of high quality modern Indian cuisine.
            Our mission is to create Spice Brew restaurants all over the UK and
            around the globe.
          </p>

          <p className="text-navyBlue xl:text-xl lg:text-lg sm:text-base text-sm">
            If you are looking for the opportunity to invest in a proven
            business model, we can make that happen for you with our franchise
            opportunity.
          </p>

          <div className="flex items-center lg:mt-14 sm:mt-12 mt-10">
            <img
              className="lg:w-8 md:w-6 sm:w-5 w-4 mr-1"
              src={vector2}
              alt=""
            />
            <h2 className="text-black Moderat-bold lg:text-2xl  text-lg">
              You will receive:
            </h2>
          </div>

          {data.map(datas => {
            return (
              <div className="flex flex-col items-start justify-start 2xl:py-5 2xl:px-5 lg:py-4 lg:px-4 md:py-3 md:px-3 sm:py-2 sm:px-2 py-1 px-1">
                <div className="flex items-center">
                  <img
                    className="lg:w-5 md:w-4 sm:w-3 w-2 mr-1"
                    src={datas.img}
                    alt=""
                  />
                  <h1 className="2xl:text-xl lg:text-lg md:text-base sm:text-sm text-sm text-normal text-navyBlue Moderat-Regular ml-2">
                    {datas.content}
                  </h1>
                </div>
              </div>
            )
          })}

          <div className="flex items-center lg:mt-14 sm:mt-12 mt-10">
            <img
              className="lg:w-8 md:w-6 sm:w-5 w-4 mr-1"
              src={vector2}
              alt=""
            />
            <h2 className="text-black Moderat-bold lg:text-2xl  text-lg">
              You will need to provide:
            </h2>
          </div>

          {dataTwo.map(datas => {
            return (
              <div className="flex flex-col items-start justify-start 2xl:py-5 2xl:px-5 lg:py-4 lg:px-4 md:py-3 md:px-3 sm:py-2 sm:px-2 py-1 px-1">
                <div className="flex items-center">
                  <img
                    className="lg:w-5 md:w-4 sm:w-3 w-2 mr-1"
                    src={datas.img}
                    alt=""
                  />
                  <h1 className="2xl:text-xl lg:text-lg md:text-base sm:text-sm text-sm text-normal text-navyBlue Moderat-Regular ml-2">
                    {datas.content}
                  </h1>
                </div>
              </div>
            )
          })}
          <p className="Moderat-Regular text-xs md:text-sm my-8">
            *Please note to preserve the integrity of our company and brand,
            applicants will be rigorously screened for suitability
          </p>
          <div className="flex flex-col items-center">
            <button className="fancy">
              <span className="top-key"></span>
              <a
                href="https://spice-brew.square.site/franchise-application"
                className="capitalize"
              >
                Apply Now
              </a>
              <span className="bottom-key-1"></span>
              <span className="bottom-key-2"></span>
            </button>
          </div>
        </div>
      </section>
      <ContactSection />
      <Footer />
    </Layout>
  )
}

export default Franchise
export const pageQuery = graphql`
  query {
    coverImgOne: file(relativePath: { eq: "Rectangle-34.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    coverImgTwo: file(relativePath: { eq: "Rectangle 38.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    coverImgThree: file(relativePath: { eq: "Rectangle 37.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
